<template>
  <div class="row">
    <div class="col s12 m6">
      <toggleComponent
        button-a-content="Add users"
        button-a-action="Add"
        button-b-content="Remove users"
        button-b-action="Remove"
        :toggle-selection="toggleSelection"
        @update="onChildUpdate"
      />
    </div>
    <div class="col s12 m6 right-align center-on-sm">
      <button
        type="submit"
        :disabled="isDisabled"
        class="btn next-step btn-causes btn-primary margin-10-bottom"
        @click="submitForm"
      >
        {{ isDisabled ? 'Please make a selection' : selectButtonText(toggleSelection, dropdownSelections.length) }}
      </button>
    </div>
  </div>

  <multiselect
    v-model="dropdownSelections"
    label="id"
    track-by="id"
    :options="toggleSelection == 'Add' ? addDropdownOptions : removeDropdownOptions"
    :multiple="true"
    :loading="isLoading"
    :searchable="true"
    :custom-label="userLabel"
  />
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue'
  import Multiselect from 'vue-multiselect'
  import axios from 'axios';
  import toggleComponent from '../forms/toggle-component.vue';

  const props = defineProps({ dataOptions: { type: Array }, submissionOptions: { type: Array } })
  const dropdownSelections = defineModel('dropdownSelections')
  const isLoading = ref(false)
  const toggleSelection = ref('Add')

  const submitActionOptions = computed(() => {
    return props.submissionOptions.find((x) => x.action == toggleSelection.value)
  })

  // Options fetched once component is mounted
  const addDropdownOptions = ref([])
  const removeDropdownOptions = ref([])

  const isDisabled = computed(() => {
    return !dropdownSelections.value || dropdownSelections?.value.length == 0
  })

  function userLabel(arg) {
    return `${arg.email}`
  }

  function submitForm() {
      axios.post(
        submitActionOptions.value.apiEndpoint, 
        { ids: dropdownSelections.value.map(x => x.id), 
          user_group_id: submitActionOptions.value.userGroupId }
        ).then(response => {
          isLoading.value = false
          // Note: Right now, this forces a page reload because the majority of the page
          // is non-reactive. If we move to using this in places with other reactive components
          // displaying the data, we'll want to revisit this.
          window.location.reload()
        }
      )
    }

  function onChildUpdate (newValue) {
    toggleSelection.value = newValue
    // Clear current selections
    dropdownSelections.value = []
  }

  function selectButtonText(action, count) {
    return `${action} ${count} ${count === 1 ? 'user' : 'users'}`
  }

  // Wait until component is mounted to fetch dropdown options
  // to ease DOM lift on page load
  onMounted(() => {
    fetchUsers()
  })

  function fetchUsers() {
    axios.get(props.dataOptions[0].fetchApiEndpoint).then(response => {
      addDropdownOptions.value = response.data['non_user_group_company_users']
      removeDropdownOptions.value = response.data['current_user_group_users']
    })
  }

</script>

<!-- Add Multiselect CSS.  -->
<style src="vue-multiselect/dist/vue-multiselect.css"></style> 