<template>
  <div class="toggle-button-group">
    <button
      class="toggle-button padding-10 left-corners"
      :class="[leftSideActive ? 'active' : '']"
      @click="[toggle(), $emit('update', activeAction())]"
    >
      <strong v-if="leftSideActive">{{ buttonAContent }}</strong>
      <span v-else>{{ buttonAContent }}</span>
    </button>
    <button
      class="toggle-button padding-10 right-corners"
      :class="[!leftSideActive ? 'active' : '']"
      @click="[toggle(), $emit('update', activeAction())]"
    >
      <strong v-if="!leftSideActive">{{ buttonBContent }}</strong>
      <span v-else>{{ buttonBContent }}</span>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps(
    { buttonAContent: { type: String, default: 'A' }, 
      buttonBContent: { type: String, default: 'B' }, 
      buttonAAction: { type: String, validator(value) {
      // The value must match one of these strings
      return actionValidator(value)
    }}, 
      buttonBAction: { type: String, validator(value) {
        return actionValidator(value)
      }}
    }
  )

const leftSideActive = ref(true)
const emit = defineEmits(['update'])

function toggle() {
  leftSideActive.value = !leftSideActive.value
}

function activeAction() {
  return leftSideActive.value ? props.buttonAAction : props.buttonBAction
}

</script>

<script>
function actionValidator(value) {
  return ['Add', 'Remove'].includes(value)
}
</script>