import { createApp } from 'vue'
import { clickOutside } from '../vue_elements/directives/click_outside'

// 1. File imports - first import your component here
import vueModal from '../vue_elements/vue-modal.vue'
import modalLink from '../vue_elements/modal-link.vue'
import visibilityToggleComponent from '../vue_elements/visibility-toggle-component.vue'
import stackedProgressBarComponent from '../vue_elements/stacked-progress-bar-component.vue'
import pieChartComponent from '../vue_elements/charts/pie-chart-component.vue'
import barChartComponent from '../vue_elements/charts/bar-chart-component.vue'
import userGroupDropdownComponent from '../vue_elements/forms/user-group-dropdown-component.vue'
import toggleComponent from '../vue_elements/forms/toggle-component.vue'

// 2. Then add it to this array
const components = [
  vueModal,
  modalLink,
  visibilityToggleComponent,
  stackedProgressBarComponent,
  pieChartComponent,
  barChartComponent,
  userGroupDropdownComponent,
  toggleComponent
]

const keyedComponents = Object.fromEntries(
  components.map((component) => [component.__name, component])
)
const elementsToHydrate = [...document.querySelectorAll('[data-vue-component]')]

elementsToHydrate.forEach((element) => {
  const props = element.dataset.vueProps ? JSON.parse(element.dataset.vueProps) : {}
  if (keyedComponents[element.dataset.vueComponent]) {
    createApp(keyedComponents[element.dataset.vueComponent], props)
      .directive('click-outside', clickOutside)
      .mount(element)
  } else {
    console.error(
      `No component found for ${element.dataset.vueComponent}. Options: ${Object.keys(
        keyedComponents
      ).join(', ')}`
    )
  }
})
